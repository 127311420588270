import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import seedIcon from '../../asset/images/seedIcon.svg';
import fertiliserIcon from '../../asset/images/fertiliserIcon.svg';
import pesticidesIcon from '../../asset/images/pesticidesIcon.svg';
import toolIcon from '../../asset/images/toolIcon.svg';
import Product_Video from '../../asset/images/ProductVideo.svg';
import whiteRightArrow from '../../asset/images/white-right-arrow.svg';
import Modal from 'react-modal'; // React Modal library
import leftNavArrow from '../../asset/images/leftNavArrow.svg';
import ProductBG from '../../asset/images/ProductBG.svg';
import Stores from './Stores';
import ProductStores from './ProductStores';
import * as apiClient from "../../common/apiClient";
Modal.setAppElement('#root'); // Set the app root element for accessibility

const Container = styled.div`
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.01) 0%, rgba(71, 166, 72, 0) 100%);

  border-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
    height: 100vh; /* Full height of the viewport */
    

`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  margin-bottom: 10%;
  overflow-y: auto; /* Enable vertical scrolling */
overflow-x:hidden;
  /* Default max-height */
    height: 100vh; /* Full height of the viewport */ 
    
`;





const ModalContent = styled.div`
  padding: 0px;
  background: #F8FAF5;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
const NavIcon = styled.div`

position: absolute;
  top: 40px;
  left: 20px;
  z-index:1 ;


`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
    margin-top: 30px;
    margin-bottom: 10px;
`;


const ServicesTitle = styled.div`
  margin-top: 10px;
  font-size: 20px;
  color: #000;
  font-family: Poppins;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;
padding-left:2px;
padding-bottom:2px;
`;

const ProductCategoryList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const ProductCategoryContainer = styled.div`
  width: 48%;
  height: 150px; 
  border-radius: 8px; 
  box-shadow: 0px 9.6px 23.04px 0px #0000001A;   
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initial state to be invisible */
  animation: ${fadeIn} 0.6s forwards; /* Apply the fade-in animation */
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
  background:white;
`;

const ProductCategoryImage = styled.img`
  width: 60px; /* Adjust as needed */
  height: auto;
  object-fit: cover;
`;

const ProductCategoryName = styled.div`
  margin-top: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1A1A1A;
;
`;

const ModalTitle = styled.h2`
display:flex;
 
font-family: Poppins;
color: #1A1A1A;

font-family: Poppins;
font-size: 25.24px;
font-weight: bolder; /* Use 'bolder' for extra bold */
line-height: 23.09px;
text-align: left;

`;
const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex;    
  
  max-height:70px
`;
const ModalTitleConatiner = styled.h2`
display:flex; 
flex-direction:column; 
padding-left:70px
`;

const CategoryFilters = styled.div`
display: flex; 
  gap: 5px; 
  justify-content: flex-start; 
  align-items: flex-start; 
  overflow-x: scroll; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrollbar */
  white-space: nowrap; /* Prevent wrapping of flex items */
  padding: 10px; /* Optional: Add some padding for better appearance */
  
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
  
  /* Hide scrollbar for Firefox */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */

`;

// Button styling for both active and inactive states
const CategoryButton = styled.div`
  padding: 8px 20px;
  border-radius: 20px;
  border: 2px solid #43cb65;
  background-color: ${props => (props.active ? '#00B800' : '#FFFFFF')};
  color: ${props => (props.active ? '#fff' : '#979797')};
  font-size: 14px;
  cursor: pointer;
  outline: none;

  &:focus, &:active {
    outline: none; /* Disable the focus outline */
    transform: none; /* Disable click animation (like scale) */
    box-shadow: none; /* Disable shadow changes on click */
  }
  &:hover {
    background-color: ${props => (props.active ? '#43cb65' : '#e6f8ea')};
  }

  transition: background-color 0.3s ease;
`;

const BannerContainer = styled.div`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #F8FAF5;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  transition: opacity 0.5s ease-in-out;
`;

const PrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
`;

const NextButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
`;

// Text overlay for the promotional message
const BannerText = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: Poppins, sans-serif;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Shadow for readability */
  b
`;
// Container for the grid with two columns per row
const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of items to next row */
  justify-content: space-between; /* Spaces out the items evenly */
  padding: 0px;
  box-sizing: border-box;
  margin-bottom:10px;
  margin-top:10px;
   
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to keep items in a single row */
  justify-content: flex-start; /* Align items to the left */
  overflow-x: auto; /* Enable horizontal scrolling */
  
  box-sizing: border-box;
  margin-bottom: 10px;
  
  margin-bottom: 100px;

  /* Hide the default scrollbar for WebKit browsers (like Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Add padding or gap between items */
  gap: 10px;
  
  /* Add smooth scrolling */
  scroll-behavior: smooth;
`;
// Card style for individual items
const Card = styled.div`
 
  border-radius: 12px;
  padding: 10px 20px 10px 20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%; /* Ensures the card stretches to full height */
  width: 34vw;

  
  
`;
const ImageCard = styled.div`
width: 40vw;
height: 150px;
box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.06); 
border-radius: 8px;
background-color:#FFFFFF;
opacity: 0px;`

// Image inside the card
const CardImage = styled.img`
width: 148px;  /* Fixed width */
  height: 148px; /* Fixed height to maintain aspect ratio */
  object-fit: contain; /* Ensure the image covers the area */
  margin-bottom: 15px;
  border-radius: 8px; /* Optional: Add border-radius for aesthetics */
 
`;

// Title of the product
const CardTitle = styled.div`
  font-size: 16px;
  
  padding-top: 10px; 
  font-family: Poppins;
  color: #333;
  width:100%;
  text-align:left;
  line-height: 1.2; /* Adjusted line-height for better text readability */
`;

// Description or additional details
const CardDescription = styled.div`
  font-size: 14px;
  color: #555;
  font-family: Poppins;
  margin: 0; 
  min-height:20px;
  width:100%;
  text-align:left;
    padding-top: 2px;
`;
const CardTitle1 = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 700;
line-height: 21px;
text-align: left;
width:100%;
  line-height: 1.2; 
    padding-top: 10px; 

`;

// Description or additional details
const CardDescription1 = styled.div`
  font-size: 14px;
  color: #555;
  font-family: Poppins;
  margin: 0; 
  min-height:20px;
  text-align:left;
  width:100%;
  padding-top:5px;
  min-height:50px;
`;

const ProductBox = styled.div`

background-image: url(${ProductBG});
background-size: 50%;
 background-position: top center;
background-repeat: no-repeat;

`
const ProductImage = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 300px; 
  margin: 0 auto;
  display: block;
  border-radius: 12px;
  object-fit: contain; /* Or use other values like contain, fill, etc. */
  
`;

const ProductInfo = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const CategoryTag = styled.span`
  display: inline-block;
  background-color: #ff6347;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex: row;
  margin-top: 20px;
`;

const SelectLabel = styled.div`
font-family: Poppins;
font-size: 14px;

line-height: 20px;
font-weight: 900;
text-align: left;
color:#1A1A1A;

`
const SelectBox = styled.div`
  flex: 1;
  margin-right: 10px;

  

  select {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 2px solid #A5A5A5;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
    height:43px;
    background:white;
    
    
  }
`;

const QuantitySelector = styled.div`
  flex: 1;

  label {
    display: block;
    font-size: 12px;
    color: #666;
  }

  input {
    width: 100%;
    padding:78px;
    border-radius: 8px;
    border: 2px solid #43cb65;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
`;

const ActionButton = styled.button`
  width: 100%;
  background-color: #00B800;

  color: white;
  padding: 16px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-family: Poppins;
font-size: 17px;
font-weight: 600;
line-height: 21px;
text-align: center;


  &:hover {
    background-color: #00b800;
  }
`;

const ProductVideo = styled.div`
  position: relative;
  margin-top: 20px;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  
  img {
    width: 100%;
    border-radius: 12px;
    display: block;
      object-fit: cover; /* Or use other values like contain, fill, etc. */

  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; /* Size of YouTube button */
    height: 50px;
    cursor: pointer;
  }
`;


const SimilarProducts = styled.div`
  margin-top: 30px;

  h3 {
    font-size: 18px;
  }
`;

const ProductRow = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-top: 10px;
`;

const ProductCard = styled.div`
  width: 120px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  background-color: white;

  img {
    width: 100%;
    border-radius: 8px;
  }

  p {
    margin: 5px 0;
  }
`;

const Label = styled.div`
  background-color: #D32F12;
  color: white;
  padding: 5px 10px;
  border-radius: 8px; 
  font-weight: 200; 
  position: absolute;
  top: 50px;
  left: 20px;
  font-family: Montserrat;
  font-size: 14px; 
  letter-spacing: 1px; 
  min-width:60px;
  text-align:center;

`;
const CardContainer1 = styled.div`
  background-color: #f0fdf4; /* Light greenish background */  
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
`;
const ProductTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align:left;
  font-family: Poppins;
font-size: 28px;
font-weight: 600;
line-height: 42px;
text-align: left;

`;
const SubTitle = styled.div`
  color: rgba(26, 26, 26, 1); 
  font-family: Poppins;
font-size: 17px;
font-weight: 600;

text-align: left;
padding-top:1px

`;
const MRPBox = styled.div`
display:flex;
flex-direction:row;
padding-top:10px

`;
const MRPLabel = styled.div`
 font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-align: left;


`;
const MRP = styled.div`
font-family: Indian Rupee;
font-size: 24px;
font-weight: 400;
line-height: 28.77px;
text-align: left;
padding-top:2px;
padding-left:12px;

`;
const MRPS = styled.div`
font-family: Poppins;
font-size: 15px;
font-weight: 600;
line-height: 24px;
text-align: left; 
padding-left:10px; 
width:100%;
`;
const ProductDescription = styled.div`
  font-size: 14px;
  color: #1A1A1A;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
letter-spacing: 0.80000000298023224px;
padding-top:10px



`;
const SpanRupees = styled.span`
font-family: Indian Rupee;
font-size: 16px;
font-weight: 400;
line-height: 19.18px;
padding-right:1px;
text-align: left;`


const RatingSection = styled.div`
  
  align-items: right;
  font-size: 16px;
  margin-right: 16px;
  
  font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 36px;
letter-spacing: -0.20000000298023224px;
text-align: left;


`;

const Rating = styled.span`
  margin-right: 5px;
  
  
`;

const StarIcon = styled.span`
  color: gold;
  font-size: 25px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  width: 170px;
  justify-content: space-between;
  background:white; 
    padding: 7.1px;
    border-radius: 8px;
    border: 2px solid #A5A5A5;
    font-size: 14px;
    cursor: pointer;
`;

const Button = styled.button`
  background: none;
  border: none;
  font-size: 27px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #1A1A1A;
  &:focus {
    outline: none;
  }
`;

const NumberDisplay = styled.span`
font-family: Poppins;
font-size: 20px;
font-weight: 900;
line-height: 24px;
text-align: left;
color:#1A1A1A
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 90%;
  border: none;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;

const PopupContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 6px;
  right: 6px;
  max-height: 30%;
  background-color: white;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  padding:14px 20px 3px 5px;
  
font-family: Poppins;
font-size: 20px;
font-weight: 900;
line-height: 24px;
text-align: left;

letter-spacing: 1px;
color:#1A1A1A;

`;


const ContentContainer1 = styled.div`
  padding:14px 20px 3px 5px;
 font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
letter-spacing: 1px;
color:#1A1A1A;

`;



const ProductCategory = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startVideo, setStartVideo] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [productFamilies, setProductFamilies] = useState([]);
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedMRP, setSelectedMRP] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);



  const [count, setCount] = useState(1);
  const increase = () => setCount(count + 1);
  const decrease = () => setCount(count > 0 ? count - 1 : 1);

  useEffect(() => {
    getProductFamily();

  }, []);
  useEffect(() => {
    setSelectedCategory(selectedCategory);

  }, [selectedCategory]);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % productFamilies.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [productFamilies.length]);


  const getProductFamily = async () => {
    await apiClient.getProductFamily().then((res) => { setProductFamilies(res); });
  }

  const searchProducts = async (id, productCode) => {
    setIsLoading(true);
    apiClient.searchProducts(id, '-').then((res) => { setProducts(res);setIsLoading(false); });
  }
  const searchProductsWithSimilar = async (id, productCode) => {
    setSelectedMRP(null);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setProductData([])
    apiClient.searchProducts(id, productCode).then((res) => {

      setProductData(res); setIsProductOpen(true)
      if (res && res.length > 0) {
        setSelectedMRP(res.filter(x => x.ProdType == 'M')[0].Price)
      }

    });
  }


  const openModal = () => {
    setIsModalOpen(true);
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation will trigger only once
    threshold: 0.1, // Trigger animation when 10% of the element is in view
  });
  const handleChange = (e) => {
    setSelectedMRP(e.target.value);  // Update state with selected price
    console.log("Selected Price:", e.target.value);  // Log the selected price
  };
  const handleLikeToBuy = async () => {
    let prods = productData.filter(x => x.Price == selectedMRP);
    if (count == 0) {
      setCount(1);
    }
    let ProductId = prods[0].ID;
    let Qty = count;
    if (Qty == 0) {
      setCount(1);
      Qty = 1;
    }
    let userId = localStorage.getItem('userId');
    await apiClient.ProductLikeToBuy({ ProductId: ProductId, Quantity: Qty, userId: userId }).then((res) => {
      setIsConfirm(true)
    });

  };
  const prevImage = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + productFamilies.length) % productFamilies.length);
  };

  const nextImage = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % productFamilies.length);
  };
  return (
    <>

      <TitleContainer>
        <ServicesTitle>{productFamilies && productFamilies.length > 0 ? (i18n.language == 'hi' ? productFamilies[0].lblGaonProductsHindi : productFamilies[0].lblGaonProducts) : ""}</ServicesTitle>
      </TitleContainer>

      <ProductCategoryList>
        {productFamilies && productFamilies.filter(x => (x.ID > 0 || x.ID != "0")).map((item =>
          <ProductCategoryContainer ref={ref} style={{ opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)' }}>
            <ProductCategoryImage src={item.Url} onClick={() => {
              setIsModalOpen(true)
              setSelectedCategory(item)
              setActiveCategory(item.ID)
              searchProducts(item.ID, '')
            }} />
            <ProductCategoryName>{i18n.language == 'hi' ? item.NameHindi : item.Name}</ProductCategoryName>
          </ProductCategoryContainer>
        ))}
      </ProductCategoryList>
      {isModalOpen && <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Prod"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
          },
        }}
      >
        <ModalContent>
          {isModalOpen && <Container>
            <ModalNavigation>
              <NavIcon >
                {<img src={leftNavArrow} onClick={closeModal} />}
              </NavIcon>
              <ModalTitleConatiner>
                <ModalTitle>{productFamilies && productFamilies.length > 0 ? (i18n.language == 'hi' ? productFamilies[0].lblProductCategoriesHindi : productFamilies[0].lblProductCategories) : ""}</ModalTitle>
              </ModalTitleConatiner>
            </ModalNavigation>

            <Content>
              <CategoryFilters>
                {productFamilies && productFamilies.length > 0 && productFamilies.map(category => (
                  <CategoryButton
                    key={category}
                    active={parseInt(activeCategory) === parseInt(category.ID)}
                    onClick={() => { setActiveCategory(category.ID); searchProducts(category.ID, '') }}
                  >

                    {i18n.language == 'hi' ? category.NameHindi : category.Name}
                  </CategoryButton>
                ))}
              </CategoryFilters>
              <BannerContainer>
               {!isLoading && 
               
              //  <BannerImage
              //     src={productFamilies.filter(x=>x.ID==activeCategory)[0]?.UrlPromo}
              //     alt="Promotional Banner"
              //   />

                productFamilies.map((product, index) => (
                  <BannerImage
                    key={product.ID}
                    src={product.UrlPromo}
                    alt={`Promo Banner ${index + 1}`}
                    style={{ opacity: index === activeIndex ? 1 : 0 }} // Show only active image
                  />
                ))
               }
  
              </BannerContainer>
              <GridContainer>
                {products.map((product) => (

                  <Card key={product.productCode} onClick={() => { searchProductsWithSimilar(0, product.ProductCode) }} >
                    <ImageCard>
                      <CardImage src={product.Url} alt={product.Name} />
                    </ImageCard>
                    <CardTitle>{i18n.language == 'hi' ? product.ProductNameHindi : product.ProductName}</CardTitle>
                    <CardDescription>{i18n.language == 'hi' ? product.LblApplyUnitHindi : product.LblApplyUnit}</CardDescription>
                  </Card>
                ))}

              </GridContainer>
              <Stores />
              <div style={{ height: 100 }} ></div>

            </Content>
          </Container>}


        </ModalContent>
      </Modal>}
      {isProductOpen &&
        <Modal
          isOpen={isProductOpen}
          onRequestClose={closeModal}
          contentLabel="Prod"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
            content: {
              inset: '0',
              padding: '0',
              borderRadius: '0',
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
            },
          }}
        >
          <ModalContent>

            {startVideo && <div style={{ height: "11%", background: "black" }} >
              <img width={30} style={{ paddingLeft: 10, paddingTop: 30 }} src={whiteRightArrow} onClick={() => setStartVideo(false)} />
            </div>}
            {startVideo && <Iframe
              src={"https://www.youtube.com/embed/RRrtIY-9i7c?autoplay=1"}
              title="YouTube video"
              allow="autoplay; fullscreen"
            />
            }

            {!startVideo && <Container>
              <ModalNavigation>
                <NavIcon >
                  {!startVideo && <img src={leftNavArrow} onClick={() => { setIsProductOpen(false); setIsModalOpen(true) }} />}
                  {startVideo && <img width={1000} src={whiteRightArrow} onClick={() => setStartVideo(false)} />}
                </NavIcon>
                {productData && productData.length > 0 && !startVideo && <ModalTitleConatiner>
                  <ModalTitle>{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].ProductNameHindi : productData.filter(x => x.ProdType == 'M')[0].ProductName}</ModalTitle>
                </ModalTitleConatiner>
                }
              </ModalNavigation>

              {productData && productData.length > 0 && <Content>
                <ProductBox>
                  <ProductImage src={productData.filter(x => x.ProdType == 'M')[0].Url} alt="Product" />
                </ProductBox>

                <ProductInfo>


                  <Label>{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].SubCategoryNameHindi : productData.filter(x => x.ProdType == 'M')[0].SubCategoryName}</Label>
                  <CardContainer1>
                    {/* Product Title and Rating */}
                    <div >
                      <div style={{ display: 'flex', flexDirection: "row", width: "100%" }} >
                        <ProductTitle style={{ width: "90%" }} >{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].ProductNameHindi : productData.filter(x => x.ProdType == 'M')[0].ProductName}</ProductTitle>
                        <RatingSection style={{ width: "10%" }}>
                          <Rating>{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].Ratings : productData.filter(x => x.ProdType == 'M')[0].Ratings}</Rating>
                          <StarIcon>★</StarIcon>
                        </RatingSection>

                      </div>
                      {productData.filter(x => x.ProdType == 'M')[0].OtherDescriptionHindi && productData.filter(x => x.ProdType == 'M')[0].OtherDescription && <SubTitle>
                        {i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].OtherDescriptionHindi : productData.filter(x => x.ProdType == 'M')[0].OtherDescription}
                      </SubTitle>}
                    </div>

                    {/* MRP */}
                    <MRPBox>
                      <MRPLabel>{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].lblMrpHindi : productData.filter(x => x.ProdType == 'M')[0].lblMrp}</MRPLabel>
                      <MRP> ₹{selectedMRP}</MRP>
                    </MRPBox>


                    <ProductDescription>
                      {i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].DescriptionHindi : productData.filter(x => x.ProdType == 'M')[0].Description}
                    </ProductDescription>
                  </CardContainer1>

                  <SelectionContainer>
                    <SelectBox>
                      <SelectLabel>{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].lblPackingHindi : productData.filter(x => x.ProdType == 'M')[0].lblPacking}</SelectLabel>
                      <select value={selectedMRP} onChange={handleChange}>
                        {productData
                          .filter((x) => x.ProdType === 'M')
                          .map((product, index) => (
                            <option key={index} value={product.Price} selected={ProductCard.Price = selectedMRP} >
                              {`${product.PackQty} ${i18n.language === 'hi' ? product.UnitHindi : product.Unit}`}
                            </option>
                          ))}
                      </select>

                    </SelectBox>
                    <QuantitySelector>

                      <SelectLabel>{i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].lblQuantityHindi : productData.filter(x => x.ProdType == 'M')[0].lblQuantity}</SelectLabel>
                      <InputContainer>
                        <Button onClick={decrease}>−</Button>
                        <NumberDisplay>{count}</NumberDisplay>
                        <Button onClick={increase}>+</Button>
                      </InputContainer>
                    </QuantitySelector>
                  </SelectionContainer>
                  <ActionButton onClick={() => { handleLikeToBuy() }}>
                    {i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].lblLikeToBuyHindi : productData.filter(x => x.ProdType == 'M')[0].lblLikeToBuy}
                  </ActionButton>

                  {/* <ProductVideo>
                  
                  <img src={Product_Video} alt="Video thumbnail" />

                  
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png"
                    alt="Play button"
                    onClick={() => { setStartVideo(true) }}
                    className="play-button"
                  />
                </ProductVideo> */}
                </ProductInfo>
                {productData.filter(x => x.ProdType != 'M').length > 0 && <SimilarProducts>
                  <div style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 600,
                    paddingLeft: '10px',
                    textAlign: 'left'
                  }} >{i18n.language == 'hi' ? productData.filter(x => x.ProdType != 'M')[0].lblSimilarProdHindi : productData.filter(x => x.ProdType != 'M')[0].lblSimilarProd}</div>
                  <HorizontalContainer>
                    {productData.filter(x => x.ProdType != 'M').map((product) => (
                      <Card key={product.id} onClick={() => { setIsProductOpen(true) }} >
                        <ImageCard>
                          <CardImage src={product.Url} alt={product.title} onClick={() => { searchProductsWithSimilar(0, product.ProductCode) }} />
                        </ImageCard>
                        <CardTitle1>{i18n.language == 'hi' ? product.ProductNameHindi : product.ProductName}</CardTitle1>
                        {/* <CardDescription1>{i18n.language == 'hi' ? product.DescriptionHindi : product.Description}</CardDescription1> */}
                        {product.OtherDescriptionHindi && product.OtherDescriptionHindi.length > 0 && <CardDescription1>{i18n.language == 'hi' ? product.OtherDescriptionHindi : product.OtherDescription}</CardDescription1>}
                        {product.OtherDescriptionHindi == null || product.OtherDescriptionHindi.length == 0 && <div style={{ height: 10 }}></div>}
                        {/* <CardDescription>{i18n.language == 'hi' ? product.LblApplyUnitHindi : product.LblApplyUnit}</CardDescription> */}


                        <MRPS><SpanRupees>₹</SpanRupees>{product.Price} / {product.PackQty} {" "}{i18n.language == 'hi' ? product.UnitHindi : product.Unit} </MRPS>



                      </Card>
                    ))}
                  </HorizontalContainer>
                </SimilarProducts>
                }



              </Content>}
            </Container>}
            {isConfirm && (
              <Backdrop >
                <PopupContainer onClick={(e) => e.stopPropagation()}>
                  <CloseButton onClick={() => {
                    setIsModalOpen(false);
                    setIsProductOpen(false);
                    setActiveCategory(0);
                    setStartVideo(false);
                    setIsConfirm(false);
                  }} >×</CloseButton>
                  <ContentContainer>
                    {i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].lblThankuHindi : productData.filter(x => x.ProdType == 'M')[0].lblThanku}
                  </ContentContainer>
                  <ContentContainer1>
                    {i18n.language == 'hi' ? productData.filter(x => x.ProdType == 'M')[0].lblConnectToHindi : productData.filter(x => x.ProdType == 'M')[0].lblConnectTo}
                  </ContentContainer1>
                  <ProductStores />
                </PopupContainer>
              </Backdrop>
            )}
          </ModalContent>
        </Modal>
      }
    </>
  );
};


export default ProductCategory;
