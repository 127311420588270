import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Verifyotpimage from '../asset/images/Verifyotpimage.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as apiClient from "../common/apiClient";
const OtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns all child elements to the left */
  height: 100vh;
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0.00) 100%);
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px;
  }

  @media (min-width: 1024px) {
    padding: 60px;
    align-items: center; /* Center align for larger screens */
  }
`;

const ImageContainer = styled.div`
  padding-top: 100px; 
  display: flex;
  justify-content: center;
  align-items: center;   
  width: 100%;

  @media (min-width: 768px) {
    padding-top: 50px;
  }
`;

const HeadingContainer = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 1.25rem; /* Responsive font size */
  font-weight: 600;
  text-align: left;
  width: 100%;
  margin-top: 40px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-top: 60px;
  }

  @media (min-width: 1024px) {
    text-align: center;
    margin-top: 80px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start; /* Aligns input boxes to the left */
  align-items: center;
  margin-top: 20px;
  width: 100%;

  @media (min-width: 1024px) {
    justify-content: center; /* Center inputs on larger screens */
  }
`;

const Input = styled.input`
  padding: 10px;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin: 5px; 
  border-radius: 8px;
  border: 1px solid rgba(0, 184, 0, 0.40);
  background: #F3FAF0;
  text-align: center;
  font-size: 1rem;
  font-family: Poppins, sans-serif;

  &::placeholder {
    color: rgba(26, 26, 26, 0.30);
    font-family: Poppins, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const Button = styled.button`
  position: fixed;
  bottom: 20px;
  width: 90%;
  padding: 20px 20px;
  background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#4caf50')};
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;
  color: #FFF;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#45a049')};
  }

  @media (min-width: 768px) {
    width: 80%;
    padding: 15px;
    font-size: 1.125rem;
  }

  @media (min-width: 1024px) {
    position: static;
    margin-top: 40px;
    width: 50%;
  }
`;

const TimerContainer = styled.div`
  margin-top: 20px;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  color: #333;
  width: 100%;
  display: flex; 
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 1024px) {
    justify-content: center;
  }
`;

const Timer = styled.div`
  color: #000; 
  margin-right: 10px;
`;

const ResendLink = styled.a`
  color: #000;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  text-decoration: underline;

  &:hover {
    color: #45a049;
  }
`;

const ResendLinkDisabled = styled.a`
  color: rgba(26, 26, 26, 0.4);
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  text-decoration: underline;
  cursor: not-allowed;
  user-select: none; /* Disable text selection */
`;

const Message = styled.div`
  margin-top: 20px;
  color: #9A9494;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  user-select: none; /* Disable text selection */

  @media (min-width: 1024px) {
    text-align: center;
  }
`;

const UseDifferentNumberLink = styled.a`
  color: #000;
  font-family: Poppins;
  font-size: 1rem;
  text-decoration: underline; 
  margin-top: 10px;
  width: 100%;
  user-select: none; /* Disable text selection */

  &:hover {
    color: #45a049;
  }

  @media (min-width: 1024px) {
    text-align: center;
  }
`;

const OtpVerificationPage = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timer, setTimer] = useState(60); 
  const [message, setMessage] = useState(""); 
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [otpLabels, setOtpLabels] = useState([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();



  useEffect(() => {
    if (timer === 0) {
      setIsResendEnabled(true);
      return;
    }

    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  
  useEffect(() => {
    GetOtp_Labels();
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const GetOtp_Labels = async() => {
    let mobileNumber=localStorage.getItem("mobileNumber"); 
  await apiClient.GetOtpLabels(mobileNumber).then((res) => {  
      setOtpLabels(res); 
  });
    //navigate('/home');
  };
  const handleVerifyOtp = async() => {
    let mobileNumber=localStorage.getItem("mobileNumber");
    let _otp= otp.join('');
    
     if( _otp.length<4)
     {return;}
      
  await apiClient.verifyOtp(mobileNumber,_otp).then((res) => {  
    if(res && res[0].result)
    {
      apiClient.showSuccessMessage(res[0].message)
      localStorage.setItem("userId",res[0].userId);
      localStorage.setItem("userType",res[0].userType);
      localStorage.setItem("token",res[0].token); 
      localStorage.setItem("displayName",res[0].displayName);       
      navigate('/home');
    }
    else 
    {
      const inputs = new Array(4).fill("");
      setOtp(inputs); 
      apiClient.showErrorMessage(res[0].message)
    } 

  });
    //navigate('/home');
  };

  const handleResendOtp = async() => {
    setTimer(60);
    setIsResendEnabled(false);
    let mobileNumber=localStorage.getItem("mobileNumber"); 
      await apiClient.signIn(mobileNumber).then((res) => {  
        if(res && res[0].result)
        {
          const inputs = new Array(4).fill("");
          setOtp(inputs); 
          apiClient.showSuccessMessage(res[0].message);  
        }
        else 
        {
          
          apiClient.showErrorMessage(res[0].message)
        }  
      });
  };

  const isButtonDisabled = otp.some(value => value === "");

  return (
    <OtpContainer>

     {otpLabels && otpLabels.length>0 &&  <>
      <ImageContainer>
        <img src={Verifyotpimage} height={200} alt="Verify OTP" />
      </ImageContainer>
      <HeadingContainer>
      {i18n.language == 'hi' ? otpLabels[0]?.lblLoginviaOtpHindi : otpLabels[0]?.lblLoginviaOtp}
      </HeadingContainer>
      
      <InputContainer>
        {otp.map((data, index) => (
          <Input
            key={index}
            type="tel"  
            maxLength="1"
            value={data}
            onChange={e => handleChange(e.target, index)}
            onFocus={e => e.target.select()}
            onKeyDown={e => {
               
              if (e.key === 'Enter') {
                handleVerifyOtp(); // Call your function here
              }
            }}
          />
        ))}
      </InputContainer>
     
      <TimerContainer>
        {!isResendEnabled &&<Timer>{isResendEnabled ? '' : `00:${timer < 10 ? `0${timer}` : timer}`}</Timer>}
        {!isResendEnabled &&<ResendLinkDisabled >
        {i18n.language == 'hi' ? otpLabels[0]?.lblResendOTPHindi : otpLabels[0]?.lblResendOTP}
          </ResendLinkDisabled>}
      </TimerContainer>
      
      {isResendEnabled && <ResendLink onClick={handleResendOtp}>{i18n.language == 'hi' ? otpLabels[0]?.lblResendOTPHindi : otpLabels[0]?.lblResendOTP}</ResendLink>}
      
      <Message>
      {i18n.language == 'hi' ? otpLabels[0]?.lblSentAnOTPtoHindi : otpLabels[0]?.lblSentAnOTPto}
      </Message>

      <UseDifferentNumberLink onClick={() => window.location.href = '/login'}>
      {i18n.language == 'hi' ? otpLabels[0]?.lblUseDiffNumberHindi : otpLabels[0]?.lblUseDiffNumber}
      </UseDifferentNumberLink>
       
      <Button onClick={handleVerifyOtp} disabled={isButtonDisabled}>
      {i18n.language == 'hi' ? otpLabels[0]?.lblVerifyHindi : otpLabels[0]?.lblVerify}
        </Button>
      </>}
    </OtpContainer>
  );
};

export default OtpVerificationPage;
 