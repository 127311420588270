import React, { useState, useEffect } from 'react';
import styled from 'styled-components'; 
import 'react-loading-skeleton/dist/skeleton.css';
import Modal from 'react-modal'; // React Modal library
import { useTranslation } from 'react-i18next';
import leftNavArrow from '../../asset/images/leftNavArrow.svg';

Modal.setAppElement('#root'); // Set the app root element for accessibility
 

const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex; 

`;
const ModalContent = styled.div`
padding: 20px;
background: #F8FAF5;
width: 100%;
height: 100%;
overflow-y: auto;
`;
const NavIcon = styled.div`
display: flex;
flex-wrap: wrap; 
width:12%;

`;
const ModalTitle = styled.h2`
display:flex;
 
font-family: Poppins;
color: #1A1A1A;

font-family: Poppins;
font-size: 25.24px;
font-weight: bolder; /* Use 'bolder' for extra bold */
line-height: 23.09px;
text-align: left;

`;
 
const Popup = (props) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const { t, i18n } = useTranslation();


  return (
    <>
       
    
      <Modal
        isOpen={isModalOpen}
        onRequestClose={props.closePopup}
        contentLabel={props.title}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: '#8FCB42',
          },
        }}
      >
        <ModalContent>
          <ModalNavigation>
          <NavIcon >
            <img src={leftNavArrow} onClick={props.closePopup} />
            </NavIcon>
          <ModalTitle>{props.title}</ModalTitle>
          </ModalNavigation>
            {props.childrens}  
        
        </ModalContent>
      </Modal>
    
    </>
  );
};

export default Popup;
