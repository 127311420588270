import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import leftNavArrow from '../asset/images/leftNavArrow.svg';
import lineSp from '../asset/images/lineSp.svg';
import editPencil from '../asset/images/editPencil.svg';
import Close from '../asset/images/Close.svg';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom'; // import the useNavigate hook
import * as apiClient from "../common/apiClient";

const MenuContainer = styled(Menu)`
  background-color: #E9F4E9;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 95% !important;
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.5) !important;
    transition: opacity 0.3s ease;
  }

 .bm-cross-button {
  height: 24px;
  width: 24px;
  top:45px !important;
  
}

/* Color/shape of close button cross */
.bm-cross {
  background: #333;
  width:100px; 
}
  

   
  .bm-burger-bars {
    background: #333;
  }
        .bm-menu {
  overflow-x: hidden !important;  /* Ensure the menu itself does not scroll */
}
.bm-burger-button
{
background-color:red}
#react-burger-menu-btn{
height:10px !important;
}

`;




const UserInfo = styled.div`
  padding: 20px 0px 0px 20px;
  background-color: #E9F4E9;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  border-bottom: 0px dotted #e0e0e0;
  width:100%;
  
  
`;
const NavContainer = styled.div`
  height:10%;
  width:100%;
  display:flex !important;
  flex-direction:row;
`;
const NavIcon = styled.div`
  padding: 20px; 
  width:10%;
  
`;


const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  
`;

const EditIcon = styled.div`
  cursor: pointer;
  margin-left:7px; 
  
`;
const CloseIcon = styled.div`
  cursor: pointer;
  margin-left:45px; 
  
`;

const UserName = styled.h2`
  margin: 0;
  color: #1A1A1A;
  width:60%;
  font-family: Poppins;
font-size: 24px;
font-weight: 700;
line-height: 28px;
text-align: left;

  text-align:left !important;
`;

const UserLocation = styled.p`
  margin: 0;
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
  color: #1A1A1A;
text-align: left;

  text-align:left;
`;

const SectionTitle = styled.h3`
padding-left: 20px;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 0px;
text-align: left;
color: #1A1A1A;
padding-top:20px;

`;

const OrderList = styled.div`
  padding: 10px 20px;
`;

const OrderItem = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const OrderStatus = styled.div`
font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: 1px;
text-align: left;
 
  color: ${props => props.status === 'PENDING' ? '#00B800' : '#9A9494'};
  
`;

const OrderedContainer = styled.div`
display:flex;
flex-direction:row;
`
const OrderedItem = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
width:80%;

color:#1A1A1A;
text-align: left; 
padding-top:2px;
`;

const OrderCancel = styled.div`
font-family: Poppins;
font-size: 13px;
font-weight: 600;
line-height: 24px;
text-align: left;
color: #FF1616;
width:20%;

`

const OrderDetails = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #9A9494;
padding-top:5px;

`;


const FarmListContainer = styled.div`
padding-left: 20px;
padding-right: 20px;
`;

const Farm = styled.div`
  box-shadow: 10px 9.6px 23.04px 10px #0000001A;
  width: 98%;
  height: auto;
  min-height: 120px;
  margin-top: 20px;
  gap: 0px;
  border-radius: 12px;
   
  background:white;
`;

const FarmNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  
  
`;

const FarmName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  font-family: Poppins;
  height: auto;
  min-height: 10px;
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
`;

const MoreIcon = styled.img`
  width: 10%;
  height: 20px;  
  padding-top: 2px;
`;

const FormDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 5px;
  padding-left: 20px;
  color: #474747;
`;


const LanguageSection = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
 

`;

const LanguageButton = styled.button`
  width: 45%;
  padding: 10px;
  background: ${props => props.active ? '#4CAF50' : '#D0E8D0'};
  color: ${props => props.active ? 'white' : '#333'};
  border: none;
  border-radius:8px 0px 0px 8px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

  &:hover {
    background: ${props => props.active ? '#45A049' : '#D0E8D0'};
  }
`;
const LanguageButton1 = styled.button`
  width: 45%;
  padding: 10px;
  background: ${props => props.active ? '#4CAF50' : '#D0E8D0'};
  color: ${props => props.active ? 'white' : '#333'};
  border: none;
  border-radius: 0px 8px 8px 0px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

  &:hover {
    background: ${props => props.active ? '#45A049' : '#D0E8D0'};
  }
`;


const LogoutButton = styled.button`
  width: 90%; 
  border-radius: 8px;
  border: 0.96px solid #9A9494;
  background-color: #FFFFFF; /* Ensure background color is set */
  color: #1A1A1A;
  margin-top: 20px; /* Add some margin to ensure it’s not too close to other elements */
  margin-bottom: 20px; /* Adjust bottom margin if needed */
  height: 50px;
  display: block; /* Ensure the button is a block element */
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-bottom:30px;
  margin-left:15px;
  margin-right:10px;
  &:hover {
    background-color: #8A8A8A; /* Slightly darker on hover for better UX */
  }
`;

const LinkButton = styled.a`
  font-family: Montserrat;
font-size: 16px;
font-weight: 500;
height:30px;
width:100%;
text-align: left;
margin-bottom:10px;
color: #9A9494;
  text-decoration: underline;
padding-left:15px;

 
`;


const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;
const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 20%; 
  min-height: 20%;
  width: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

`;
 

const InputLabel = styled.label`
  font-size: 16px;
  margin-bottom: 10px; 
  font-weight: bold;
  align-self: flex-start;
`;

const TextInput = styled.input`
  width: 100%;
  padding-top: 40px;
  font-size: 20px;
  border: none;
  border-bottom: 2px solid black;
  outline: none;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns all children to the right */
  width: 100%;
`;

const Button = styled.button`
  padding: 10px 10px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  
  
  &:hover {
    opacity: 0.8;
  }
  
  &:first-child {
    margin-right: 20px;
  }
`;

const Seprator = styled.div`
  width: 90%; 
border: 1px dashed rgba(151, 151, 151, 1);
margin-top:10px;

margin-left:20px;
margin-right:20px;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopTitle = styled.h2`
font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;

`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
`;

const Content = styled.p`
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;


`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns all children to the right */
`;

 

const ButtonCancel = styled.button`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;
const DrawerMenu = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isEditName, setIsEditName] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(0);
  
  const [name, setName] = useState('');
  const [bookings, setBookings] = useState([]);
  const [farmList, setFarmList] = useState([])
  const [userProfile, setUserProfile] = useState([])
  const navigate = useNavigate(); // Initialize the hook
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleArrowClick = () => {
    props.onClose();
  };
  useEffect(() => {
    if(localStorage.getItem('displayUserName'))
    {
      setName(localStorage.getItem('displayUserName'));
    }
    else
    {
      setName('Guest');
    }
    getMyProfile();
    loadBooking();
    loadMyFarm();
    setTimeout(() => setLoading(false), 2000); // Simulate loading delay
  }, []);
  const handlePolicy = () => {
    window.open("http://www.vagroscience.com/PrivacyPolicy.html", "_blank");
  };
  const updateName = async () => {   
    let formBody={ 
      userId:localStorage.getItem('userId'), 
      name:name

    }; 
    await apiClient.updatename(formBody).then((res) => {
      if(res[0].result)
      {
        setIsEditName(false);
        getMyProfile();
        localStorage.setItem('displayUserName',name);
      }
      else{
        apiClient.showSuccessMessage(i18n.language =='hi'?res[0].messageHindi:res[0].message)
      }
        
      }); 
    
  }

  const getMyProfile = async () => {
    setLoading(true);
    await apiClient.getMyProfile(localStorage.getItem('userId')).then((res) => {
      setUserProfile(res);
      
    });

  }
  const loadMyFarm = async () => {
    setLoading(true);
    await apiClient.GetMyFarm().then((res) => {
      setFarmList(res);
      setLoading(false);
    });

  }
  const loadBooking = async () => {  
    
    setBookings([])
    await apiClient.myBookings(localStorage.getItem('userId'),).then((res) => {
      setBookings(res);
      }); 
    
  }
    // Call loadBooking when the menu opens
    const handleStateChange = async(state) => {
      if (state.isOpen) {
         
        loadBooking();
        loadMyFarm();
      }
    };
    
    const handleClose = async () => {
      setIsCancelOpen(false);

    }
    const handleConfirm = async (id) => {
      setIsCancelOpen(true);
      setSelectedBookingId(id)

    }
    
    const handleCancel = async ( ) => {

      let formBody = { ServiceBookingId: selectedBookingId};
      await apiClient.CancelBookService(formBody).then((res) => {
        
        if (res[0].result) {
          
          apiClient.showSuccessMessage( i18n.language == 'hi' ? res[0].messageHindi : res[0].message )
          loadBooking();
          setIsCancelOpen(false);
        }
        else {
          apiClient.showErrorMessage(i18n.language == 'hi' ? res[0].messageHindi : res[0].message)
        }
  
  
      });
      
    };
  
  return (
    <MenuContainer isOpen={props.isOpen} disableAutoFocus   customCrossIcon={ false } 
    onStateChange={handleStateChange} >
      <NavContainer>
        {/* <NavIcon onClick={handleArrowClick}>
          <img src={leftNavArrow} alt="Close Menu" />
        </NavIcon> */}
        <UserInfo>
          <UserNameContainer>
            <UserName>{userProfile && userProfile.length>0?userProfile[0].FirstName:"Guest"}</UserName>
            <EditIcon> <img src={editPencil} height={30} onClick={()=>{setIsEditName(true)}} /></EditIcon>
            <CloseIcon> <img src={Close} height={30} onClick={()=>{props.onClose()}} /></CloseIcon>
            
          </UserNameContainer>
          <UserLocation>{localStorage.getItem("sublocation")}</UserLocation>
        </UserInfo>
      </NavContainer>
          <Seprator/>
      {/* <img src={lineSp} style={{ paddingLeft: 0, paddingRight: 0 }} alt="Close Menu" /> */}
      {bookings && bookings.length>0 && <SectionTitle>{i18n.language =='hi'?bookings[0].lbMyBookingHindi:bookings[0].lbMyBooking}</SectionTitle>}
      <OrderList>
      {bookings && bookings.map((booking, index) => ( 
         <OrderItem key={index+'BI'}>
          <OrderStatus status={booking.Status=="Created"?"PENDING":""}>{i18n.language =='hi'?booking.StatusInHindi:booking.Status}</OrderStatus>
          <OrderedContainer><OrderedItem>  {i18n.language =='hi'?booking.NameHindi:booking.Name}  </OrderedItem>
          {booking.StatusID && booking.StatusID==167 && <OrderCancel onClick={()=>{handleConfirm(booking.ID)}}>{i18n.language =='hi'?booking.lblbBookingCancelBtnHindi:booking.lbBookingCancelBtn}</OrderCancel>}
          </OrderedContainer>
          <OrderDetails>{i18n.language =='hi'?booking.SlotHindi:booking.Slot} </OrderDetails>
          
        </OrderItem>
        ))}
      </OrderList>
      <Seprator/>
      {/* <img src={lineSp} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }} alt="Close Menu" /> */}
      {farmList && farmList.length>0 &&<SectionTitle>{i18n.language == 'hi' ? farmList[0].lblMyFarmListingHindi: farmList[0].lblMyFarmListing}</SectionTitle>}
     
      <FarmListContainer>
        {loading ? (
          <>
            <Skeleton height={120} style={{ marginBottom: 20, borderRadius: 12 }} />
            <Skeleton height={120} style={{ marginBottom: 20, borderRadius: 12 }} />
          </>
        ) : (
          <>
           {farmList && farmList.map((farm, index) => (
              <Farm key={index+'AB'} >
                <FarmNameContainer >
                  <FarmName>{farm.farmName}</FarmName>
                  {/* <MoreIcon src={rightArrow} /> */}
                </FarmNameContainer>
                <FormDetail>
                {t('Farm area')}: <span style={{ marginLeft: 5 }}>{i18n.language == 'hi' ? farm.farmAreaHindi: farm.farmArea}</span>
 
                </FormDetail>
                <FormDetail>
{t('Current crop')}: <span style={{ marginLeft: 5 }}>{i18n.language == 'hi' ? farm.currentCropHindi: farm.currentCrop}</span>
                </FormDetail>
                <FormDetail>
                {t('Current age')}: <span style={{ marginLeft: 5 }}>{i18n.language == 'hi' ? farm.currentAgeHindi:farm.currentAge}</span>
                </FormDetail>
              </Farm>
            ))}

            
          </>
        )}
      </FarmListContainer>
      <Seprator/>
      <SectionTitle>{t('Select App Language')}</SectionTitle>
      <LanguageSection>
        <LanguageButton active={i18n.language === 'en'} onClick={() => {changeLanguage('en');localStorage.setItem("lan","en")}}  >English</LanguageButton>
        <LanguageButton1 active={i18n.language === 'hi'} onClick={() => {changeLanguage('hi');localStorage.setItem("lan","hi")}} >हिंदी</LanguageButton1>
      </LanguageSection>
 
      <LinkButton onClick={handlePolicy}>{t('Privacy policy')}</LinkButton>

      <LogoutButton onClick={() => { navigate('/login') }}>{t('Logout')}</LogoutButton>
      <div style={{ minHeight: 1 }}></div>
      {isEditName && <Backdrop>
        <PopupContainer>  
        <InputLabel htmlFor="name">{i18n.language === 'hi'?userProfile[0].lblEnterNameHindi:userProfile[0].lblEnterName}</InputLabel>
        <TextInput type="text" value={name} onChange={(e)=>{setName(e.target.value)}} id="name" />
      <ButtonContainer>
        <Button onClick={()=>{setIsEditName(false)}}>{i18n.language === 'hi'?userProfile[0].lblCancelHindi:userProfile[0].lblCancel}</Button>
        <Button onClick={()=>{updateName()}}>{i18n.language === 'hi'?userProfile[0].lblSubmitHindi:userProfile[0].lblSubmit}</Button>
      </ButtonContainer>
        </PopupContainer>
      </Backdrop>}
      {isCancelOpen && bookings && bookings.length>0 && <Backdrop>
        <PopupContainer>
          <PopupHeader>
            <PopTitle>  
              {i18n.language == 'hi' ? bookings[0].lblBookingCancelHindi : bookings[0].lblBookingCancel}
            </PopTitle>
            <CloseButton onClick={handleClose}>&times;</CloseButton>
          </PopupHeader>
          <Content>

        
            {i18n.language == 'hi' ?bookings[0].lblBookingConfMsgHindi :bookings[0].lblBookingConfMsg}
          </Content>
          <ButtonGroup>
            <Button onClick={handleCancel}>
              {i18n.language == 'hi' ? bookings[0].lblBookingYesMsgHindi : bookings[0].lblBookingYesMsg}
            </Button>
            <ButtonCancel onClick={handleClose}>
              {i18n.language == 'hi' ? bookings[0].lblBookingNoMsgHindi : bookings[0].lblBookingNoMsg}
            </ButtonCancel>
          </ButtonGroup>
        </PopupContainer>
      </Backdrop>}
    </MenuContainer>
       
  );
};

export default DrawerMenu;
