import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Navigation from './Navigation';
import rightarrowblack from '../../../asset/images/rightarrowblack.svg';
import { useTranslation } from 'react-i18next';
// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
 
  background: #f0f7ed;
  height: 100vh;
  position: relative;
`;

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const BackArrow = styled.div`
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

const NotificationIcon = styled.div`
  font-size: 24px;
  position: relative;
  cursor: pointer;
`;

const PaymentTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`;

const PaymentSubtitle = styled.span`
  color: #666;
`;

const PaymentDetails = styled.div`
  margin-bottom: 20px;
`;

const PaymentRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: ${({ isTotal }) => (isTotal ? '2px solid #ccc' : '1px solid #eaeaea')};
  font-weight: ${({ isTotal }) => (isTotal ? 'bold' : 'normal')};
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
color:rgba(26, 26, 26, 1);

`;
const PaymentLabel = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;
  padding-left: 20px;
`
const PaymentTotalLabel = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 700;
line-height: 23.08px;
text-align: left;
padding-left:20px;

`

const PaymentValue = styled.div`
font-family: Indian Rupee;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;
padding-right: 20px;
`


const PaymentTotal = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 18px;
  border-top:1px solid rgba(217, 217, 217, 1);
  border-bottom:1px solid rgba(217, 217, 217, 1);
  font-family: Indian Rupee;;
font-size: 18px;
font-weight: 700;
line-height: 24px;
padding-right: 20px;

`;

const PaymentOption = styled.div`
  margin-top:20px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background:white;
  padding:10px;
  border: 1px solid #E0E0E0;
  border-radius:8px;
  margin-left:20px;
  margin-right:20px;
`;

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1px;
`;

// Hide the default radio input
const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

// Custom radio appearance
const CustomRadio = styled.span`
  width: 15px;
  height: 15px;
  border: 1px solid #D9D9D9; /* outer circle color */
  border-radius: 50%;
  display: inline-block;
  position: relative; 
  
  margin-right: 20px;

  /* Add background color when checked */
  ${HiddenRadio}:checked + & {
    background-color: #F7F7F7;
  }

  /* Inner dot */
  ${HiddenRadio}:checked + &::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color:#1A1A1A; /* inner dot color */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const OptionLabel = styled.span`
  
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;

`;

// Slider Styles
const SliderContainer = styled.div`
  width: 90%;
  max-width: 400px;
  height: 50px;
  background-color:#00B800;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 5px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
  margin: 0 auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
`;

const SliderText = styled.span`
  font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 21px;
text-align: center;
color:white;
  margin-left: 100px;
`;

const SliderHandle = styled.div`
   width: 40px;
  height: 40px;
  background: ${({ confirmed }) => (confirmed ? '#FFFFFF' : '#FFFFFF')}; /* Handle color changes */
  border-radius: 5px;
  position: absolute;
  left: ${({ position }) => position}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: left 0.3s ease, background 0.3s ease; /* Smooth transition */
  margin-left: 10px;
`;

const Arrow = styled.img`
  font-size: 18px;
  color: #00cc00;
`;

const BookButton = styled.button`
 background: rgba(0, 184, 0, 1);

  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 25px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px); /* Ensure it fits within the padding of the container */
  max-width: 400px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2bbbad;
  }
`;

// Main Payment Component
const Payment = (props) => {
  const [position, setPosition] = useState(0);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const sliderRef = useRef(null);
  const { t, i18n } = useTranslation();

  const handleDragStart = (e) => {
    if (confirmed || loading) return;

    const startX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    const sliderWidth = sliderRef.current.offsetWidth;

    const handleDragMove = (event) => {
      const clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
      const newPos = Math.min(Math.max(clientX - startX, 0), sliderWidth - 50); // 50px is the handle width
      setPosition(newPos);

      // If slider reaches the end
      if (newPos >= sliderWidth - 50) {
        completeSlide();
      }
    };

    const handleDragEnd = () => {
      // Reset if not fully dragged
      if (position < sliderWidth - 50 && !confirmed) {
        setPosition(0);
      }

      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('touchmove', handleDragMove);
    };

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDragMove);
    document.addEventListener('touchend', handleDragEnd);
  };

  const completeSlide = () => {
    setConfirmed(true);
    setPosition(sliderRef.current.offsetWidth - 50); // Lock slider to the end
    setLoading(true);
    props.OnBookingConfiremed();
    // Call API
    // axios.post('https://api.example.com/confirm', { paymentId: 123 })
    //   .then((response) => {
    //     console.log('Payment confirmed:', response.data);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error('Error confirming payment:', error);
    //     setLoading(false);
    //     setConfirmed(false);
    //     setPosition(0); // Reset if API fails
    //   });
  };
  const RadioButton = ({ label, name, value, checked, onChange }) => (
    <RadioWrapper>
      <HiddenRadio
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <CustomRadio />
      <OptionLabel>{label}</OptionLabel>
    </RadioWrapper>
  );
  return (
    <Container>
      <div style={{ padding: '20px'}}> <Navigation serviceName={i18n.language =='hi'?props.serviceData[0].NameHindi:props.serviceData[0].Name}   title={"Payment"} onCancel={props.onCancel} /></div>
        

      <PaymentDetails>
        <PaymentRow>
          <PaymentLabel >
          {i18n.language =='hi'?props.serviceData[0].lblChargeHindi:props.serviceData[0].lblCharge}  
            </PaymentLabel>
          
          <PaymentValue   >{props.serviceData[0].Rate}</PaymentValue>
        </PaymentRow>
        <PaymentRow>
          <PaymentLabel>
            
            {i18n.language =='hi'?props.serviceData[0].lblServiceChargeHindi:props.serviceData[0].lblServiceCharge}  
            </PaymentLabel>
          <PaymentValue>{props.serviceData[0].ServiceCharge}</PaymentValue>
        </PaymentRow>
        <PaymentRow> 
          <PaymentLabel  >
          {i18n.language =='hi'?props.serviceData[0].lblGstHindi:props.serviceData[0].lblGst}
          {" "}
             {props.serviceData[0].GstPer}%</PaymentLabel>
          <PaymentValue>{props.serviceData[0].GstAmt}</PaymentValue>
        </PaymentRow>
        <PaymentTotal>
          <PaymentTotalLabel>
          {i18n.language =='hi'?props.serviceData[0].lblTotalHindi:props.serviceData[0].lblTotal}  
          </PaymentTotalLabel>
          <span>{props.serviceData[0].TotalAmt}</span>
        </PaymentTotal>
      </PaymentDetails>

      <PaymentOption>
      <RadioButton
        label= {i18n.language =='hi'?props.serviceData[0].DeliveryMethodHindi:props.serviceData[0].DeliveryMethod}  
        name="payment"
        value="cod"
        checked={true}
//        onChange={handleRadioChange}
      />
      </PaymentOption>
      <BookButton onClick={() =>{props.OnBookingConfiremed();}} >
      {i18n.language =='hi'?props.serviceData[0].lblConfirmHindi:props.serviceData[0].lblConfirm}  
     
      </BookButton>
      {/* Slider Button */}
      {/* <SliderContainer ref={sliderRef}>
        <SliderText>{loading ? 'Processing...' : confirmed ? 'Confirmed' : 'Slide to Confirm'}</SliderText>
        <SliderHandle
          position={position}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        >
          <Arrow src={rightarrowblack}/>
        </SliderHandle>
      </SliderContainer> */}
    </Container>
  );
};

export default Payment;
