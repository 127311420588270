import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as http from "./http";

const getApi = () => {
  let hostname = window.location.host;
  var arrValue = hostname.split(".");
  var result = arrValue[0];
  if (result.includes("localhost") === true) {
    result = "http://localhost:8499/";
  } else {
    result = "https://api.gaoon.in/";
  }
  result = "https://api.gaoon.in/";
  
  return result;
};

export const APIUrl = getApi();

 export const showErrorMessage = (message) => {
    toast.error(message, {
        
         theme: "colored" 
    }); 
};
export const showSuccessMessage = (message) => {
  toast.success(message, {
      
       theme: "colored" 
  }); 
};
export const validateEmail = (email) => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
export const post = async(resource,payload,header) => { 
  let api = APIUrl + `${resource}`;
  return http.post(api, payload).then((response) => {
    return response;
  });
}
export const get = async(resource,header) => { 
  let api = APIUrl + `${resource}`;
  return http.get(api).then((response) => {
    return response;
  });
}

export const formatDate = (date) => {
  if(date==null)
  {
    return "NA";
  }
  const originalTimestamp = date;
  return new Date(originalTimestamp).toLocaleString('en-US', { timeZone: 'UTC' });
};

export const validateMobileNumber = (number) => {
  // Regular expression to check if the number has exactly 10 digits
  const regex = /^\d{10}$/;
  return regex.test(number);
};
export const signIn = (mobileNo) => {
  let api = APIUrl + "/getOTP/"+mobileNo;
  return http.get(api).then(response => {
    return response;
});
}
export const GetCrops = () => {
  let api = APIUrl + "/product-groups/GetCropsDetails";
  return http.get(api).then(response => {
    return response;
});
}

export const GetOtpLabels = (mobileNo) => {
  let api = APIUrl + `/getOtpLabels/${mobileNo}`;
  return http.get(api).then(response => {
    return response;
});
}

export const GetMyFarm = () => {
  let api = APIUrl + "/user-farms/myfarm/"+localStorage.getItem('userId');
  return http.get(api).then(response => {
    return response;
});
}

export const GetAddFarmLabels= () => {
  let api = APIUrl + "/getAddFarmLabels";
  return http.get(api).then(response => {
    return response;
});
}

export const verifyOtp = (mobileNo,otp) => {
  let api = APIUrl + "/verifyOTP/"+mobileNo+"/"+otp;
  return http.get(api).then(response => {
    return response;
});
}

export const addFarm = (data) => {
  let api = APIUrl + "/user-farms";
  return http.post(api, data).then(response => {
      return response;
  });
}

export const BookService = (data) => {
  let api = APIUrl + "/users/BookService";
  return http.post(api, data).then(response => {
      return response;
  });
}
export const updatename= (data) => {
  let api = APIUrl + "/users/updatename";
  return http.post(api, data).then(response => {
      return response;
  });
}
export const CancelBookService = (data) => {
  let api = APIUrl + "/users/CancelBookService";
  return http.post(api, data).then(response => {
      return response;
  });
}
export const getMyProfile = (userId) => {
  let api = APIUrl + `/getMyProfile/${userId}`;
  return http.get(api).then(response => {
    return response;
});
}


export const getnearbystore = (latitude,longitude,lang) => {
  let api = APIUrl + `/getnearbystore/${latitude}/${longitude}/${lang}`;
  return http.get(api).then(response => {
      return response;
  });
}
export const myBookings = (userId) => {
  let api = APIUrl + `/myBookings/${userId}`;
  return http.get(api).then(response => {
      return response;
  });
}

export const GetServices = () => {
  let api = APIUrl + "/getServices";
  return http.get(api).then(response => {
    return response;
});
}


export const getProductFamily = () => {
  let api = APIUrl + "/product-families/getProductFamily";
  return http.get(api).then(response => {
    return response;
});
}


export const searchProducts = (id,productCode) => {
  let api = APIUrl + `/product-families/searchProducts/${id}/${productCode}`;
  return http.get(api).then(response => {
    return response;
});
}

export const ProductLikeToBuy = (data) => {
  let api = APIUrl + "/product-families/ProductLikeToBuy";
  return http.post(api, data).then(response => {
      return response;
  });
}







