import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Modal from 'react-modal'; // React Modal library
import addFarm from '../../asset/images/addFarm.svg';
import rightArrow from '../../asset/images/rightArrow.svg';
import addIcon from '../../asset/images/addIcon.svg';
import leftNavArrow from '../../asset/images/leftNavArrow.svg';
import tick_Icon from '../../asset/images/tickIcon.svg';
import { useTranslation } from 'react-i18next';
import * as apiClient from "../../common/apiClient";
Modal.setAppElement('#root'); 

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
    margin-bottom: 10px;
`;
const ServicesTitle = styled.div`
margin-top: 10px;
margin-bottom: 5px;
font-family: Poppins;
font-size: 20px; 
font-weight: bold; 
z-index: 2; 
font-family: Poppins; 
color: #000;
`;

const AddFarmButton = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  user-select: none;
  background: #00B800;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    background: #00a300;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  &:active {
    background: #008f00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const AddFarmButtonIcon = styled.img`
  width: 20%;
  height: 40px;  
  padding-left: 1px;
`;

const AddFarmButtonTitle = styled.div`
  display: flex; 
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  font-family: Poppins;
  height: 100%;
  font-size: 19px;
  font-weight: 600;
  text-align: left; 
  color: #FFFFFF; 
  padding: 0 10px; 
`;

const FarmListContainer = styled.div`
`;

const Farm = styled.div`
  box-shadow: 0px 9.6px 23.04px 0px rgba(0, 0, 0, 0.1);  
  width: 98%;
  height: auto;
  min-height: 140px;
  margin-top: 20px;
  gap: 0px;
  border-radius: 12px;
  padding: 5px;
  
  background:white;
`;

const FarmNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-top: 10px;
  
`;

const FarmName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  font-family: Poppins;
  height: auto;
  min-height: 10px;
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
`;

const MoreIcon = styled.img`
  width: 10%;
  height: 20px;  
  padding-top: 2px;
`;

const FormDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 5px;
  padding-left: 20px;
  color: #474747;
`;


const ModalContent = styled.div`
padding: 20px;
background: #F8FAF5;
width: 100%;
height: 100%;
overflow-y: auto;
`;

const ModalTitle = styled.h2`
display:flex;
 
font-family: Poppins;
color: #1A1A1A;

font-family: Poppins;
font-size: 25.24px;
font-weight: bolder; /* Use 'bolder' for extra bold */
line-height: 23.09px;
text-align: left;

`;

const ModalForm = styled.form`
display: flex;
flex-direction: column;
gap: 16px;
`;

const ModalInput = styled.input`
width: 87%;
height: 46px;
padding-left:10px;  
border: 1px ; 
border-radius: 8px;

font-size: 14px;
font-family: Poppins;
background: #FFFFFF;
border: 1px solid #D9D9D9
`;
const ModalAreaInput = styled.input`
width: 50%;
height: 46px;
padding-left:10px;  

border-radius: 8px;
border: 1px solid #D9D9D9;
font-size: 16px;
font-family: Poppins;
background: #FFFFFF;

`;


const ModalLabel = styled.label`
color: #1A1A1A;
margin-bottom: 0px;
font-family: Poppins;
font-size: 16px;
font-weight: 900;
line-height: 18px;
text-align: left;

`;

const AddCropContainer = styled.div`
  width: 90%;
  
  height:50px;
  margin-top: 15px;
  
  
  display:flex,
  
  
  flex-direction: row;
  display:flex;
  border: 1px ; 
border-radius: 8px;

font-size: 14px;
font-family: Poppins;
background: #FFFFFF;
border: 1px solid #D9D9D9

`;

const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex;
  
   

`;

const ModalCropInput = styled.input`
width: 90%;
height: 46px;
padding-left:10px;  
border: 1px ; 
border-radius: 8px;
outline:none;

font-size: 16px;
font-family: Poppins;
background: #FFFFFF;
border: 0px solid #D9D9D9
`;

const AddIcon = styled.div`
display: flex;
flex-wrap: wrap;
width:10%;

`;
const NavIcon = styled.div`
display: flex;
flex-wrap: wrap; 
width:12%;

`;


const CropAgeContainer = styled.div`
display: flex;
flex-wrap: wrap;
width:90%;
gap: 10px;
`;

const CropAgeButton = styled.button`
padding: 10px;
flex: 1;
min-width: calc(50% - 10px);
border-radius: 8px;
border: 1px solid #C9C9C9;
background-color: #fff;
font-size: 16px;
font-family: Poppins;
cursor: pointer;

&:hover {
  background-color: #E9F5E8;
}

&:active {
  background-color: #D1E9D2;
}

&.active {
  background-color: #00B800;
  color: white;
}
`;

const ModalButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 90%;
  
  padding-bottom: 20px;
  
`;

const ModalButton = styled.button`
  padding: 15px;
  background-color: #00B800;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 21px;
text-align: center;

  width: 100%;

  &:hover {
    background-color: #00a300;
  }

  &:active {
    background-color: #008f00;
  }
`;
const AreaInputContainer = styled.div`
display: flex;
width:90%;
gap: 10px;
`;

const AreaSelect = styled.select`
padding: 12px;
  border-radius: 8px;
  width: 50%;
  border: 1px solid #C9C9C9;
  font-size: 14px;
  font-family: Poppins;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  appearance: none; /* Hides the default select arrow */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="8 12 16 20 24 12"></polyline></svg>');

  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  padding-right: 30px; /* Space for the custom arrow */
 
  -webkit-appearance: none;
  -moz-appearance: none;

`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const CropCategory = styled.h3`
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CropGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

const CropItem = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  width: 90px;
  height: 90px
   
  
`;
const CropItemContainerV1 = styled.div`
   position: relative;
  cursor: pointer;
    outline: none;  /* Removes outline or focus effect */
  background: inherit; 
 
  /* Prevent text selection */
  user-select: none;
  
  /* Prevent active state */
  &:active {
    background: inherit;
    outline: none;
  }

  /* Prevent hover state */
  &:hover {
    background: inherit;
  }

  /* Prevent focus state */
  &:focus {
    outline: none;
  }
  
  
`;
const CropItemContainer = styled.div`
  display:flex;
  flex-direction:column;
  
`;
const CropItemName = styled.div`
margin-top:2px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
   width: 80px; 
`;



const CropImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TickIcon = styled.img`
  position: absolute;
  width: 34px;
  height: 34px;
  
  right: 30px;
  top: -8px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;
const MyFarm = (props) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCropOpen, setIsModalCropOpen] = useState(false);
  const [selectedCropAge, setSelectedCropAge] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState(null); // To store the selected crop
  const [selectedFarm, setSelectedFarm] = useState(null); // To store the selected crop
  const [cropCategories, setCropCategories] = useState([]); // To store the selected crop
  const [unitId, setunitId] = useState(161);


  const [farmList, setFarmList] = useState([])


  const { t, i18n } = useTranslation();
  const [crops, setCrops] = useState([]);
  const [formLabels, setFormLabels] = useState([]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Simulate loading delay
  }, []);


  useEffect(() => {
    GetAddFarmLabels();
    loadCrops();
    loadMyFarm()
  }, []);

  const loadCrops = async () => {
    await apiClient.GetCrops().then((res) => {

      setCrops(res);
      const uniqueCropCategories = Array.from(new Map(
        res.map(item => [item.category, { category: item.category, categoryHindi: item.categoryHindi }])
      ).values());

      setCropCategories(uniqueCropCategories);


    });

  }
  const loadMyFarm = async () => {
    setLoading(true);
    await apiClient.GetMyFarm().then((res) => {
      setFarmList(res);
      setLoading(false);
    });

  }
  const GetAddFarmLabels = async () => {
    setLoading(true);
    await apiClient.GetAddFarmLabels().then((res) => {
      setFormLabels(res); 
    });

  }
  
  const openModal = () => {
    
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    
  };
  const closeCropModal = () => {

    setIsModalCropOpen(false);
    setIsModalOpen(true);
  };

  const handleCropAgeSelect = (age) => {
    setSelectedCropAge(age);
  };
  const handleFormSubmit = async (e) => {
    
    e.preventDefault();
   
    const formData = new FormData(e.target);
    const farmData = {
      farmName: formData.get('farmName'),

      cropAgeId: parseInt(selectedCropAge?.ageCode),
      areas: parseInt(formData.get('area')),
      areaId: 0,
      unitId: parseInt(unitId),
      createdBy: parseInt(localStorage.getItem('userId')),
      createdDate: new Date().toISOString(),
      isActive: true

    };
     if(farmData.farmName==null || farmData.farmName==""  || farmData.farmName.toString().trim()=="" )
    {
      apiClient.showErrorMessage(i18n.language == 'hi' ? formLabels[0]?.lblValidationFarmNameHindi : formLabels[0]?.lblValidationFarmName );
      return;
    }
    
    if (selectedCrop != null) {
      farmData.productGroupId = parseInt(selectedCrop.id);

    }
    else {
      apiClient.showErrorMessage(i18n.language == 'hi' ? formLabels[0]?.lblValidationCurrentCropHindi : formLabels[0]?.lblValidationCurrentCrop );
      return;
    }
    if (selectedCropAge == null) {
      apiClient.showErrorMessage(i18n.language == 'hi' ? formLabels[0]?.lblValidationCropAgeHindi : formLabels[0]?.lblValidationCropAge );
      return;
    } 
    if (unitId == null) {
      apiClient.showErrorMessage(i18n.language == 'hi' ? formLabels[0]?.lblValidationAreaUnitHindi : formLabels[0]?.lblValidationAreaUnit);
      return;
    }
    
//     "lblValidationFarmName": "Please Enter Farm Name",
// "lblValidationFarmNameHindi": "\r\nकृपया फार्म का नाम दर्ज करें",
// "lblValidationCurrentCrop": "Please Select Current Crop",
// "lblValidationCurrentCropHindi": "कृपया वर्तमान फसल का चयन करें",
// "lblValidationCropAge": "Please Select Crop Age",
// "lblValidationCropAgeHindi": "कृपया फसल की आयु चुनें\r\n",
// "lblValidationArea": "Please Enter Area",
// "lblValidationAreaHindi": "\r\nकृपया क्षेत्र दर्ज करें",
// "lblValidationAreaUnit": "Please Select Area Unit",
// "lblValidationAreaUnitHindi": "Please Select Area Unit",


    await apiClient.addFarm(farmData).then(res => {
      setSelectedCrop(null);
      setSelectedCropAge(null);
      setIsModalOpen(false);
      apiClient.showSuccessMessage("Farm is added");
      loadMyFarm();

    }).catch(err => {
      // apiClient.showErrorMessage("Unable to Add");
    });

    // Handle form submission logic here
  };
  const handleCropSelect = (crop) => {
    setSelectedCrop(crop);
  };

  const handleSaveCropSelection = () => {
    // Save crop selection logic here
    closeCropModal();
  };


  return (
    <>
      {props.showAdd &&  formLabels && formLabels.length>0 &&  
        <TitleContainer style={{marginTop:10}} >
          <ServicesTitle> 
            {i18n.language == 'hi' ? formLabels[0]?.lblAddYourFarmHindi : formLabels[0]?.lblAddYourFarm} 
            </ServicesTitle>
        </TitleContainer> 
      } 
      {props.showAdd && formLabels && formLabels.length>0 &&  <AddFarmButton onClick={openModal}>
        <AddFarmButtonIcon src={addFarm} />
        <AddFarmButtonTitle>
        {i18n.language == 'hi' ? formLabels[0]?.lblAddYourFarmAndCropHindi : formLabels[0]?.lblAddYourFarmAndCrop}
          </AddFarmButtonTitle>
      </AddFarmButton>
      } 
      <FarmListContainer>
        {loading ? (
          <>
            <Skeleton height={160} style={{ marginBottom: 20, borderRadius: 12 }} />
            <Skeleton height={160} style={{ marginBottom: 20, borderRadius: 12 }} />
          </>
        ) : (
          <>

            {farmList && farmList.map((farm, index) => (
              
              <div>
                
              <Farm style={selectedFarm == farm ? { border: "2px solid #00B800", borderRadius: "8px" } : null} onClick={() => { setSelectedFarm(farm); if(props.onSelected && props.onSelected(farm)); }}>
                <FarmNameContainer>
                  <FarmName>{farm.farmName}</FarmName>
                  {/* <MoreIcon src={rightArrow} /> */}
                </FarmNameContainer>
                <FormDetail>
                {t('Farm area')}: <span style={{ marginLeft: 5 }}>{i18n.language == 'hi' ? farm.farmAreaHindi: farm.farmArea}</span>
 
                </FormDetail>
                <FormDetail>
{t('Current crop')}: <span style={{ marginLeft: 5 }}>{i18n.language == 'hi' ? farm.currentCropHindi: farm.currentCrop}</span>
                </FormDetail>
                <FormDetail>
                {t('Current age')}: <span style={{ marginLeft: 5 }}>{i18n.language == 'hi' ? farm.currentAgeHindi:farm.currentAge}</span>
                </FormDetail>
              </Farm>
              </div>
            ))}
          </>
        )}
      </FarmListContainer>

      {/* Add Farm Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Farm"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
          },
        }}
      >
        <ModalContent>
          <ModalNavigation>
            <NavIcon >
              <img src={leftNavArrow} onClick={closeModal} />
            </NavIcon>
            <ModalTitle>{t('Add Farm')}</ModalTitle>
          </ModalNavigation>

          <ModalForm onSubmit={handleFormSubmit}>

            <ModalInput type="text" name="farmName" id="farmName" placeholder={i18n.language == 'hi' ? formLabels[0]?.lblAddFarmNameHindi: formLabels[0]?.lblAddFarmName} />
            <AddCropContainer onClick={() => { setIsModalCropOpen(true) }}>
              <ModalCropInput placeholder={i18n.language == 'hi' ? formLabels[0]?.lblAddYourCurrentCropHindi : formLabels[0]?.lblAddYourCurrentCrop} type="text" value={i18n.language == 'hi' ? selectedCrop?.nameHindi : selectedCrop?.name} disabled name="currentCrop" id="currentCrop" required />

              <AddIcon >
                <img src={addIcon} />
              </AddIcon>
            </AddCropContainer>
            <ModalLabel htmlFor="cropAge">{i18n.language == 'hi' ? formLabels[0]?.lblCropAgeHindi : formLabels[0]?.lblCropAge}</ModalLabel>
            <CropAgeContainer>
              {[
                { "ageCode": "163", age: i18n.language == 'hi' ? formLabels[0]?.lblCrop10to0Hindi : formLabels[0]?.lblCrop10to0 }
              ,{ "ageCode": "164", age: i18n.language == 'hi' ? formLabels[0]?.lblCrop01to10Hindi : formLabels[0]?.lblCrop01to10 },
               { "ageCode": "165", age: i18n.language == 'hi' ? formLabels[0]?.lblCrop11to35Hindi : formLabels[0]?.lblCrop11to35  }, 
               { "ageCode": "166", age: i18n.language == 'hi' ? formLabels[0]?.lblCrop36to50Hindi : formLabels[0]?.lblCrop36to50 }
            ].map((item) => (
                <CropAgeButton key={item} className={selectedCropAge == item ? 'active' : ''} onClick={() => handleCropAgeSelect(item)} type="button">
                  {item.age}
                  
                </CropAgeButton>
              ))}
            </CropAgeContainer>

            <ModalLabel htmlFor="area">{i18n.language == 'hi' ? formLabels[0]?.lblAreaStarHindi : formLabels[0]?.lblAreaStar}</ModalLabel>
            <AreaInputContainer>
              <ModalAreaInput type="number" name="area" id="area"  />
              <AreaSelect name="areaUnit" id="areaUnit" onChange={(e) => setunitId(e.target.value)} required>
                {formLabels.map((item) => ( <option value={item.ID} selected={item.ID==unitId} >{i18n.language == 'hi' ? item?.lblFarmUnitHindi : item?.lblFarmUnit }</option>))}
                
              </AreaSelect>
            </AreaInputContainer>
            <ModalButtonContainer>
              <ModalButton type="submit" onClick={() => {


              }} >{i18n.language == 'hi' ? formLabels[0]?.lblSaveAndContinueHindi : formLabels[0]?.lblSaveAndContinue}</ModalButton>
            </ModalButtonContainer>
          </ModalForm>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isModalCropOpen}
        onRequestClose={closeCropModal}
        contentLabel="Add Crop"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: '#8FCB42',
          },
        }}
      >
        <ModalContent>
          <ModalNavigation>
            <NavIcon >
              <img src={leftNavArrow} onClick={closeCropModal} />
            </NavIcon>
            <ModalTitle>{i18n.language == 'hi' ? formLabels[0]?.lblAddYourFarmHindi : formLabels[0]?.lblAddYourFarm}</ModalTitle>
          </ModalNavigation>



          {cropCategories.map(categoryItem => (
            <CropContainer>
              <CropCategory>

                {i18n.language == 'hi' ? categoryItem.categoryHindi : categoryItem.category}</CropCategory>
              <CropGrid>
                {crops.filter(x => x.category == categoryItem.category).map(crop => (
                  <CropItemContainerV1>
                  <CropItemContainer>
                    <CropItem
                      key={crop.id}
                      selected={selectedCrop?.id === crop.id}
                      onClick={() => handleCropSelect(crop)}
                    >

                      <CropImage src={i18n.language == 'hi' ? crop.imageHindi : crop.image} alt={i18n.language == 'hi' ? crop.nameHindi : crop.name} />
                      {/*  */}
                    </CropItem>
                    <CropItemName>
                      {i18n.language == 'hi' ? crop.nameHindi : crop.name}
                    </CropItemName>
                  </CropItemContainer>
                  <TickIcon src={tick_Icon} visible={selectedCrop?.id === crop.id} />
                  </CropItemContainerV1>
                ))}
              </CropGrid>
            </CropContainer>
          ))
          }

          <div style={{ height: 100 }}></div>
          <ModalButtonContainer>
            <ModalButton type="submit"
              onClick={() => {
                if (selectedCrop) {
                  setIsModalCropOpen(false)
                }
                else {
                  apiClient.showErrorMessage(i18n.language == 'hi' ? formLabels[0].lblAddYourCurrentCropHindi : formLabels[0].lblAddYourCurrentCrop);
                }

              }}
            >{i18n.language == 'hi' ? formLabels[0]?.lblValidationCurrentCropHindi : formLabels[0]?.lblValidationCurrentCrop}</ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>

    </>
  );
};

export default MyFarm;
