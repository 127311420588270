import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {  
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Check if the prompt was dismissed before and show the button again
    const dismissed = localStorage.getItem('installPromptDismissed');
    if (dismissed === 'true') {
      setIsVisible(true);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    const choiceResult = await deferredPrompt.userChoice;

    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the install prompt');
      localStorage.removeItem('installPromptDismissed'); // Reset if accepted
    } else {
      console.log('User dismissed the install prompt');
      localStorage.setItem('installPromptDismissed', 'true');
    }

    setDeferredPrompt(null);
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div style={{
        'display': 'flex',
        'justify-content': 'center',  /* Centers horizontally */
        'align-items': 'center',      /* Centers vertically */
        'width': '97px',
        'height': '42px', 
        'border-radius': '12px',
        'background': '#1C1C1C',
        'box-shadow': '0px 6px 12px 0px #0000001A',
        'font-family': 'Poppins',
        'font-size': '12px',
        'font-weight': 600,
        'line-height': '16px',
        'text-align': 'center',      /* Ensures text stays centered */
        'color': '#FFFFFF'
    }
    } onClick={handleInstallClick}>
        {t('Install App')}
      </div>
    )
  );
};

export default InstallButton;
 
