import React, { useState } from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import bookingConfirmedSpray from '../../../asset/images/bookingConfirmedSpray.svg';
import { useTranslation } from 'react-i18next';
import * as apiClient from "../../../common/apiClient";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #F0F8EF;
  
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
`;

const HelpButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
`;

const BookingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 0px 0px 0px;
  padding-bottom:10px;
`;

const Image = styled.img`
   width: 200px;        /* Define the width of the image */
  height: 200px;       /* Define the height of the image */
  border-radius: 50%;  /* This makes the image a circle */
  object-fit: cover;   /* Ensure the image covers the area properly */
  border: 0px solid #000; /* Optional: Add a border for aesthetics */
`;

const Title = styled.h1`
  font-family: Poppins;
font-size: 17px;
font-weight: 600;
line-height: 24px;
text-align: center;

`;

const Description = styled.p`
  color: #9A9494;
  font-size: 14px;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;  
 
  font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;

const CancelButtonContainer = styled.div`

display: flex;
  justify-content: center;
  align-items: center;
  
  border-bottom:1px solid #D9D9D9;
  

`;
const CancelButton = styled.button`
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 30px 5px 20px 0px;
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;


const ExploreButton = styled.button`
  background-color: #00C853;
  color: white;
  font-size: 18px;
  padding: 15px;
  border-radius: 8px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border: none;
`;
const FinalAmount = styled.div`
font-family: Indian Rupee;
font-size: 24px;
font-weight: 600;
line-height: 24px;
text-align: left;

`;
const FinalDate = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;
const BookingId = styled.div`
font-family: Poppins;

text-align:left;
font-size: 16px;
font-weight: 400;
line-height: 24px;

 border-top: 1px solid #D9D9D9;
 padding-top:20px;
 padding-left:20px;
 
 

`;
const BookingCancelLabel = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: center;
 padding-top:20px;

`;



const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;
const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 17%; 
  min-height: 17%;
  width: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopTitle = styled.h2`
font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;

`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
`;

const Content = styled.p`
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;


`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns all children to the right */
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin-left: 54px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonCancel = styled.button`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;
const BookingConfirmed = (props) => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [bookingCancelDetail  , setBookingCancelDetail  ] = useState({}); 
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleConfirm = async () => {

    let formBody = { ServiceBookingId: props.bookingDetail[0].BookingID };
    await apiClient.CancelBookService(formBody).then((res) => {
      debugger
      setIsCancelled(res[0].result)
      if (res[0].result) {
        
        apiClient.showSuccessMessage( i18n.language == 'hi' ? res[0].messageHindi : res[0].message )
        setIsCancelled(true);
        setBookingCancelDetail(res)
      }
      else {
        apiClient.showErrorMessage(i18n.language == 'hi' ? res[0].messageHindi : res[0].message)
      }


    });
    //setIsBookingConfirmed(true); setIsPayment(false)
    console.log('Booking Canceled');
    setIsOpen(false);
  };


  return (
    <Container>
      <div>
        <div style={{padding:20}} ><Navigation serviceName={""} title={i18n.language == 'hi' ? props.bookingDetail[0].lblBookingHindi : props.bookingDetail[0].lblBooking} onCancel={props.onCancel} /></div>

        <BookingInfo>
          <Image src={props.bookingDetail && props.bookingDetail.length > 0 ? props.bookingDetail[0].BookingURL :null} alt="Booking Image" />
          <Title>
            {i18n.language == 'hi' && props.bookingDetail && props.bookingDetail.length>0? props.bookingDetail[0].lblServiceNameHindi :  props.bookingDetail[0].lblServiceName}   
            
            </Title>
          {!isCancelled  && <Description>{ i18n.language == 'hi'  ? props.bookingDetail[0].lblBookingNotesHindi : props.bookingDetail[0].lblBookingNotes} </Description>}
         
        </BookingInfo>
        
        {/* "result": true,
        "lblServiceName": "Field Inspection",
        "lblServiceNameHindi": "खेत निरीक्षण",
        "lblBookingNotes": "Customer must be available at the farm at the selected time",
        "lblBookingNotesHindi": "ग्राहक को चयनित समय पर खेत में उपलब्ध होना चाहिए",
        "BookingID": 24,
        "BookingNo": "FI24100002",
        "BookingDate": "04 Oct 2024",
        "BookingDateHindi": "4 अक्टूबर 2024",
        "ServiceTimeSlot": "08:00AM - 05:00PM",
        "ServiceTimeSlotHindi": "प्रातः 08:00 बजे - सायं 05:00 बजे तक\r\n",
        "LblTotal": "₹ 365.18",
        "LblTotalHindi": "₹ 365.18",
        "lblBooking": "Booking Confirmed For ",
        "lblBookingHindi": "बुकिंग पुष्टीकरण",
        "lblCancelBooking": "Cancel booking",
        "lblCancelBookingHindi": "बुकिंग रद्द करें",
        "lblExploreMore": "Explore more",
        "lblExploreMoreHindi": "और खोजें",
        "BookingURL": "https://vertagro.blob.core.windows.net/vertagrimages/Field-Insp.png" */}
        {!isCancelled && <BookingId style={isCancelled ? { color: 'red' } : {}}>
          {i18n.language == 'hi' ? props.serviceData[0].lblBookingIdHindi : props.serviceData[0].lblBookingId}  {" "}
          {props.bookingDetail && props.bookingDetail.length > 0 ? props.bookingDetail[0].BookingNo : ""}
        </BookingId>}
        {isCancelled && <BookingId style={isCancelled ? { textAlign: 'center',borderBottom:0 } : {}}>
          {i18n.language == 'hi' ? props.serviceData[0].lblBookingIdHindi : props.serviceData[0].lblBookingId}  {" "}
          {props.bookingDetail && props.bookingDetail.length > 0 ? props.bookingDetail[0].BookingNo : ""}
        </BookingId>}
        {isCancelled && <BookingCancelLabel style={isCancelled ? {color: 'red' } : {}}>
        { bookingCancelDetail && bookingCancelDetail.length > 0 && i18n.language == 'hi' ?bookingCancelDetail[0]?.lblBookingCancelledHindi : bookingCancelDetail[0]?.lblBookingCancelled}
        </BookingCancelLabel>}
        {/* "lblBookingCancelled": "Booking Cancelled",
        "lblBookingCancelledHindi": "बुकिंग रद्द कर दी गयी" */}
        
        {!isCancelled && <Details>
          <div>
            <FinalDate>
            {    i18n.language == 'hi' ? props.bookingDetail[0].BookingDateHindi : props.bookingDetail[0].BookingDate}
            </FinalDate>
            <FinalDate>
            {i18n.language == 'hi' ? props.bookingDetail[0].ServiceTimeSlotHindi : props.bookingDetail[0].ServiceTimeSlot}
            </FinalDate>
          </div>
          <div>
            <FinalAmount  > {props.serviceData[0].TotalAmt}</FinalAmount>
          </div>
        </Details>}
        {!isCancelled &&<CancelButtonContainer>
           <CancelButton onClick={() => { setIsOpen(true) }} >

           {i18n.language == 'hi' ? props.bookingDetail[0].lblCancelBookingHindi : props.bookingDetail[0].lblCancelBooking}
          </CancelButton> 
        </CancelButtonContainer>}

      </div>
      <ExploreButton onClick={props.onExploreMore}>

        {i18n.language == 'hi' ? props.serviceData[0].lblExploreMoreHindi : props.serviceData[0].lblExploreMore}
      </ExploreButton>
      {isOpen && <Backdrop>
        <PopupContainer>
          <PopupHeader>
            <PopTitle>  
              {i18n.language == 'hi' ? props.serviceData[0].lblBookingCancelHindi : props.serviceData[0].lblBookingCancel}
            </PopTitle>
            <CloseButton onClick={handleClose}>&times;</CloseButton>
          </PopupHeader>
          <Content>

            {i18n.language == 'hi' ? props.serviceData[0].lblBookingConfMsgHindi : props.serviceData[0].lblBookingConfMsg}
          </Content>
          <ButtonGroup>
            <Button onClick={handleConfirm}>
              {i18n.language == 'hi' ? props.serviceData[0].lblBookingYesMsgHindi : props.serviceData[0].lblBookingYesMsg}
            </Button>
            <ButtonCancel onClick={handleClose}>
              {i18n.language == 'hi' ? props.serviceData[0].lblBookingNoMsgHindi : props.serviceData[0].lblBookingNoMsg}
            </ButtonCancel>
          </ButtonGroup>
        </PopupContainer>
      </Backdrop>}
    </Container>
  );
};

export default BookingConfirmed;
