import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StoreIcon from '../../asset/images/StoreIcon.svg';
import starIcon from '../../asset/images/star.svg';
import locationIcon from '../../asset/images/locationIcon.svg';
import callIcon from '../../asset/images/callIcon.svg';
import * as apiClient from "../../common/apiClient";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  color: #1A1A1A;
  font-family: Montserrat;
`;

const ServicesTitle = styled.h2`
  
  font-size: 20px;
  color: #000;
`;

const AddStoreButton = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  user-select: none;
  background: #00B800;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: #00a300;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background: #008f00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const StoreListContainer = styled.div``;

const Store = styled.div`
  box-shadow: 10px 9.6px 23.04px 10px #0000001A;
  width: 98%;
  height: auto;
  min-height: 155px;
  margin-top: 10px;
  gap: 0px;
  border-radius: 12px;
  padding: 5px 0px 0px 0px;
  background:white;
`;

const StoreNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

const StoreName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  font-family: Poppins;
  height: auto;
  min-height: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
  padding-left: 12px;
`;

const MoreIcon = styled.img`
  width: 10%;
  height: 25px;
  padding-right: 10px;
`;

const StoreRatingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

const RatingIcon = styled.img`
  object-fit: cover;
  width: 6%;
`;

const Rating = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #979797;
  padding-left: 4px;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StoreAddress = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  min-height: 40px;
  padding: 5px;
  padding-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #474747;
  opacity: 0.8;
  
`;

const StoreMapContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding-left: 14px;
  margin-top: 10px;
  
`;

const StoreMapIcon = styled.div`
  width: 5%; 
  padding-top: 4px;
`;

const StoreMapLabel = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 8px;
  padding-left: 10px;
  color: #9A9494;
  width: auto;
  min-width: 50vw;
  
`;

const StoreDistance = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 8px;
  color: #9A9494;
  width: 22%;
  min-width: 20vw;
  
`;

const StoreCall = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 45%;
  padding-right: 28px;
  
  
`;
const ProductStores = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [farmList, setFarmList] = useState([])
  setTimeout(() => {
    setLoading(false);
  }, 10000); // Simulate loading delay
  const handleCall = (phoneNumber) => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Please enter a phone number.");
    }
};
 
useEffect(() => {
  const getLocationAndFetchWeather = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          loadNearByStore(latitude, longitude);
        },
        error => {
          console.error('Error getting location:', error);
          // Handle error, maybe set some default location or show an error message
          setLoading(false);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      // Handle the case where geolocation is not supported
      setLoading(false);
    }
  };

  getLocationAndFetchWeather();
}, []);
const openGoogleMaps = (latitude, longitude) => {
  if (latitude && longitude) {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank');
  } else {
      alert('Latitude and longitude are required.');
  }
};
const loadNearByStore = async (latitude, longitude) => {
  
  setLoading(true); 
  await apiClient.getnearbystore(latitude,longitude,i18n.language).then((res) => { 
    setLoading(false);  
    if(res && res.result && res.result.length>0)
    {
      setStoreData( res.result);  
    }
    
  });

}

  return (
    <div >
      
      <StoreListContainer>
        {loading ? (
          <Store>
            <StoreNameContainer>
              <StoreName><Skeleton width={200} /></StoreName>
              <MoreIcon as={Skeleton} width={30} height={25} />
            </StoreNameContainer>
            <StoreRatingContainer>
              <RatingIcon as={Skeleton} width={20} height={20} />
              <Rating><Skeleton width={40} /></Rating>
            </StoreRatingContainer>
            <StoreAddress><Skeleton count={2} /></StoreAddress>
            <StoreMapContainer>
              <StoreMapIcon><Skeleton circle={true} height={22} width={22} /></StoreMapIcon>
              <StoreMapLabel><Skeleton width={80} /></StoreMapLabel>
              <StoreDistance><Skeleton width={50} /></StoreDistance>
              <StoreCall><Skeleton width={40} /></StoreCall>
            </StoreMapContainer>
          </Store>
        ) : (

          <>

          {storeData && storeData.map((store, index) => (
            <>
            {index>0 && <div style={{minHeight:10}} ></div>}
            <Store>
            <StoreNameContainer>
              <StoreName>{i18n.language == 'hi' ? store?.AccountNameHindi : store?.AccountName}</StoreName>
              <MoreIcon src={StoreIcon} />
            </StoreNameContainer>
            <StoreRatingContainer>
              <RatingIcon src={starIcon} />
              <Rating>{store.Rating}</Rating>
            </StoreRatingContainer>
            <StoreAddress> 
              
              {i18n.language == 'hi' ? store?.Address1Hindi : store?.Address1}
            </StoreAddress>
            <StoreMapContainer>
              <StoreMapIcon><img height={22} src={locationIcon} /></StoreMapIcon>
              <StoreMapLabel onClick={() => openGoogleMaps(store.Latitude,store.Longitude)}>
              {i18n.language == 'hi' ? store?.lblShowOnMapHindi : store?.lblShowOnMap}            
              {" | "} {store.distance} {i18n.language == 'hi' ? store.lblKmHindi : store.lblKm}
              </StoreMapLabel>
              {/* <div style={{width:'1vw',paddingRight:1,marginTop:6}} >|</div> */}
              {/* <StoreDistance>{store.distance} {i18n.language == 'hi' ? store.lblKmHindi : store.lblKm}</StoreDistance> */}
              <StoreCall><img width={28} src={callIcon} onClick={() => handleCall(store.MobilePhone)} /></StoreCall>
            </StoreMapContainer>
          </Store>
         </> 
          ))}
        </>
         
        )}
      </StoreListContainer>
    </div>
  );
};

export default ProductStores;
