import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Enter your mobile number": "Enter your mobile number",
      "Send OTP": "Send OTP",
      "Login via OTP": "Login via OTP",
      "Resend OTP": "Resend OTP",
      "Use a different number": "Use a different number",
      "Verify": "Verify OTP",
      "Search products": "Search products",
      "Services": "Services",
      "Field Inspection": "Field Inspection",
      "Field Spray": "Field Spray",
      "Soil Testing": "Soil Testing",
      "Add your farm": "Add your farm",
      "Add your farm & crop": "Add your farm & crop",
      "Current age": "Current age",
      "Current crop": "Current crop",
      "Farm area": "Farm area",
      'gaoON Products': 'gaoON Products',
      'Seeds': 'Seeds',
      'Fertilisers': 'Fertilisers',
      'Pesticides': 'Pesticides',
      'Tools': 'Tools',
      'Stores near by': 'Stores near by',
      'Install App': 'Install App',
      "Terms of use": "Terms of use",
      "Privacy policy": "Privacy policy",
      "Logout": "Logout",
      "Farm History": "Farm History",
      "Select App Language": "Select App Language",
      "My Orders": "My Orders",
      "PENDING": "PENDING",
      "COMPLETED": "COMPLETED",
      "Select date & time": "Select date & time",
      "Select Date": "Select Date",
      'Monday': 'Monday',
      'Tuesday': 'Tuesday',
      'Wednesday': 'Wednesday',
      'Thursday': 'Thursday',
      'Friday': 'Friday',
      'Saturday': 'Saturday',
      'Sunday': 'Sunday',
      "AM":"AM",
      "PM":"PM",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
      "Book Now":"Book Now",
      "Schedule":"Schedule",
      "Payment":"Payment",
      "Booking Confirmed":"Booking Confirmed",
      "gaoON agent will assign shortly for the field spray.please be available on selected date and time":"gaoON agent will assign shortly for the field spray.please be available on selected date and time",
      "Add Farm": "Add Farm", 

    }
  },
  hi: {
    translation: {
      "Enter your mobile number": "अपना मोबाइल संख्या दर्ज करे",
      "Send OTP": "ओटीपी भेजें",
      "Login via OTP": "OTP के माध्यम से लॉगिन करें",
      "Resend OTP": "ओटीपी पुनः भेजें",
      "Use a different number": "कोई और नंबर उपयोग करें",
      "Verify": "सत्यापित करें",
      "Search products": "उत्पाद खोजें",
      "Services": "सेवाएँ",
      "Field Inspection": "मैदान निरीक्षण",
      "Field Spray": "फील्ड स्प्रे",
      "Soil Testing": "भूमि निरीक्षण",
      "Add your farm": "अपना फार्म जोड़ें",
      "Add Farm": "फार्म जोड़ें", 
      "Add your farm & crop": "अपना खेत और फसल जोड़ें",
      "Current age": "वर्तमान आयु",
      "Current crop": "वर्तमान फसल",
      "Farm area": "कृषि क्षेत्र",
      'gaoON Products': 'गाँव के उत्पाद',
      'Seeds': 'बीज',
      'Fertilisers': 'उर्वरक',
      'Pesticides': 'कीटनाशक',
      'Tools': 'उपकरण',
      'Stores near by': 'आस-पास दुकानें',
      'Install App': 'ऐप इंस्टॉल करें',
      "Terms of use": "उपयोग की शर्तें",
      "Privacy policy": "गोपनीयता नीति",
      "Logout": "लॉगआउट",
      "Farm History": "फार्म का इतिहास",
      "Select App Language": "ऐप भाषा चुनें",
      "My Orders": "मेरे आदेश",
      "PENDING": "लंबित",
      "COMPLETED": "पूर्ण",
      "Select date & time": "तिथि और समय चुनें",
      "Select Date": "तिथि चुनें",
      'Monday': 'सोमवार',
      'Tuesday': 'मंगलवार',
      'Wednesday': 'बुधवार',
      'Thursday': 'गुरुवार',
      'Friday': 'शुक्रवार',
      'Saturday': 'शनिवार',
      'Sunday': 'रविवार',
      "AM":"पूर्वाह्न",
      "PM":"अपराह्न",
      "January": "जनवरी",
      "February": "फ़रवरी",
      "March": "मार्च",
      "April": "अप्रैल",
      "May": "मई",
      "June": "जून",
      "July": "जुलाई",
      "August": "अगस्त",
      "September": "सितंबर",
      "October": "अक्टूबर",
      "November": "नवंबर",
      "December": "दिसंबर",
      "Book Now":"अभी बुक करें",
      "Schedule":"अनुसूची करें",
      "Payment":"भुगतान",
      "Booking Confirmed":"बुकिंग सफल रही",
      "gaoON agent will assign shortly for the field spray.please be available on selected date and time":"गाओऑन एजेंट जल्द ही फील्ड स्प्रे के लिए असाइन करेगा। कृपया चयनित तिथि और समय पर उपलब्ध रहें।"








    }
  }
};
let lang=localStorage.getItem('lan')

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:lang?lang: 'hi', // Set default language to Hindi
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
